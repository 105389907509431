<template>
  <v-app-bar app color="gray" elevate-on-scroll :dense="true">
    <v-app-bar-nav-icon @click="toggleDrawer" />
    <v-spacer />
    <v-toolbar-title class="">{{ title }}</v-toolbar-title>
    <v-spacer />
    <v-btn
      v-if="!authenticated"
      color="primary"
      rounded
      class="d-none d-sm-flex mx-1"
      to="login"
    >
      <v-icon left> mdi-account-outline </v-icon> Inicia sesión
    </v-btn>
    <v-btn
      v-if="!authenticated"
      rounded
      text
      class="d-none d-sm-flex"
      to="signup"
    >
      Regístrate
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  components: {},
  data: () => ({
    title: process.env.VUE_APP_NAME,
    collapseOnScroll: false,
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    item: 1,
    loading: false,
    openDialog: false,
    openQRDialog: false,
  }),
  methods: {
    toggleDrawer() {
      this.$store.commit("toggleDrawer");
    },
  },
  computed: {
    authenticated: {
      get() {
        return this.$store.getters["auth/isAuthenticated"];
      },
    },
  },
};
</script>
